const { io } = require('socket.io-client')
const QRCode = require('qrcode')
const { getCookie, setCookie } = require('./cookie')
const { uuidv4 } = require('./uuid')
const { api } = require('./api')
require('./clipboard')

var cookiedata = getCookie()?.data
if (cookiedata == null) {
    document.location.href = "/"
}
var token = cookiedata.event
var projectnumber = cookiedata.eventid
var presentertoken = cookiedata.token
var wssServer = cookiedata.wssServer
// var wssServer = "http://localhost:4447"

if (!cookiedata || !projectnumber || !presentertoken || !token || !wssServer || !projectnumber) {
    document.location.href = "/"
}


var timerBlink
var warnTime
var warnTimeUnix
var timerCount

var started = false

var connection


let uuid = uuidv4()


const logout = () => {
    wsSend({ command: 'logout' })
    setCookie('', -99999999999)
    window.location.href = "/"
}

document.getElementById('logOut').addEventListener('click', () => {
    logout()
})


const checkAuth = async () => {
    let cookie = await api({ uri: 'data', task: 'auth' })
    if (cookie.data.error) {
        window.location.href = "/?logout=cookie"
    }
    setTimeout(() => {
        checkAuth()
    }, 15 * 60 * 1000)
}
checkAuth()

const connectToServer = () => {
    var socket = io.connect(wssServer, {
        'reconnection delay': 500,
        'reconnection limit': Infinity,
        'max reconnection attempts': Infinity
    })
    return socket;
};

const socket = connectToServer();



const start = async () => {
    socket.on("connect", () => {
        connection = 'connected'
        console.log(socket.id);
        let startUpData = {
            token: token + '_' + projectnumber,
            client: 'presenter',
            id: presentertoken
        }
        socket.emit("StartUp", startUpData)
        wsSend({ command: 'presenterConnected', value: socket.id })
    });
    socket.io.on("reconnect", (attempt) => {
        console.log('Reconnected!!!', attempt)
        connection = 'connected'
        wsSend({ command: 'presenterConnected', value: socket.id })
    });

    rc = 0
    socket.io.on("reconnect_attempt", () => {
        console.log('try reconnect', rc++);
    });


    getMSG()
    sendHeartbeat()
    started = true
}

const sendHeartbeat = () => {
    wsSend({ command: 'heartbeat' })
    setTimeout(() => {
        sendHeartbeat()
    }, 3000)
}

socket.on("disconnect", (reason) => {
    console.error(reason)
    connection = 'disconnected'
})

window.onunload = window.onbeforeunload = () => {
    socket.close();
};


// Send Websocket Commands
const wsSend = async (data) => {
    data.presenter = data.presenter ? data.presenter : presentertoken
    data.uuid = uuid
    let command = data.command
    //if (command !== 'heartbeat' && command !== 'presenter-pong') console.log('wss-Out: ', data)

    socket.emit("SendControl", data)
    if (command == 'left' || command == 'right' || command == 'home' || command == 'end') {
        active(command)
        sound(command)
    }
}

const getMSG = async () => {
    socket.on("SendControl", data => {
        let command = data.command
        let presenter = data.presenter
        let client = data.client
        let controller = data.controller
        let value = data.value

        if (presenter === presentertoken) {
            if (command != 'heartbeat' && command != 'controller-ping' && command != 'presenter-pong') console.log('wss-In: ', data)

            if (command == 'left' || command == 'right' || command == 'home' || command == 'end') {
                active(command)
                sound(command)
            }
            if (command == "countDownStart" || command == "countDownStop") countDownTimer(command, value)

            if (command == "countUpStart" || command == "countUpStop") countUpTimer(command, value)

            if (command == "setTime") setTime(value)
            if (command == "countdownUp") timerCount = 'up'
            if (command == "countdownDown") timerCount = 'down'

            if (command == 'requestCall') callSender(client)
            if (command == 'closeCall') closeCall('all')
        }
        if (command == 'controller-ping') {
            wsSend({ command: 'presenter-pong' })
            keepAwake()
        }
        if (command == "timerSettings") {
            let d = value.split(';').join(',')
            d = JSON.parse(d)
            timerBlink = d.timerBlink
            warnTime = d.timerWarn
            setWarnTime()
        }
    })

}

const controlBtns = document.querySelectorAll('[data-cmd]')
controlBtns.forEach((element) => {
    element.addEventListener('click', () => {
        wsSend({ command: element.dataset.cmd })
    })
})


document.addEventListener('keydown', (e) => {
    if (keyOn.checked == true) {
        if (e.code === "ArrowLeft") wsSend({ command: 'left' })
        if (e.code === "ArrowRight") wsSend({ command: 'right' })
        if (e.code === "Space") wsSend({ command: 'right' })
        if (e.code === "Enter") wsSend({ command: 'right' })
        if (e.code === "End") wsSend({ command: 'end' })
        if (e.code === "Escape") wsSend({ command: 'end' })
        if (e.code === "Home") wsSend({ command: 'home' })
    }
    if (e.code === "KeyM") document.getElementById('soundOn').click()
    if (e.code === "KeyK") document.getElementById('keyOn').click()
})


const active = (id) => {
    document.getElementById(id).classList.add('active')
    setTimeout(() => { document.getElementById(id).classList.remove('active') }, 300)
}

var play = new Audio()

document.getElementById('soundOn').addEventListener('click', () => {
    if (soundOn.checked) {
        play.autoplay = true
    } else {
        play.autoplay = false
    }
})

const sound = (id) => {
    if (soundOn.checked) {
        if (id == 'right') play.src = 'assets/sounds/next.mp3'
        if (id == 'left') play.src = 'assets/sounds/previous.mp3'
        if (id == 'home') play.src = 'assets/sounds/home.mp3'
        if (id == 'end') play.src = 'assets/sounds/end.mp3'
    }
}


if (token) {
    start()
}

const qrURL = 'https://' + window.location.hostname + '/?t=' + presentertoken
const qrImage = document.getElementById('qrImage')
const qrOpts = {
    errorCorrectionLevel: 'H',
    version: 7,
    color: {
        dark: "#1c1c1e",
        light: "#ffffffe6"
    }
}
QRCode.toCanvas(qrImage, qrURL, qrOpts, (error) => {
    if (error) console.error(error)
})

document.getElementById('token').innerText = `Token: ${presentertoken}`

qrCode.addEventListener('click', () => qrShow.classList.remove('hide'))
closeSharing.addEventListener('click', () => qrShow.classList.add('hide'))
copyLink.addEventListener('click', () => {
    try {
        Clipboard.copy(qrURL)
        copyLink.classList.add('bg-green')
    } catch (error) {

    }
})


// Receive modal/iframe messages
window.onmessage = function (e) {
    if (e.data == 'fileUploaded') {
        let data = { command: 'fileUploaded' }
        wsSend(data)
    }
    if (e.data == 'fileDeleted') {
        let data = { command: 'fileDeleted' }
        wsSend(data)
    }
};


/// TIMERS
const timerH = document.getElementById('hour')
const timerM = document.getElementById('min')
const timerS = document.getElementById('sec')
const timerField = document.getElementById('timerField')
const timerSeperator = document.getElementsByClassName('seperator')

const setTime = (value) => {
    if (value == 0) {
        timerH.innerText = '00'
        timerM.innerText = '00'
        timerS.innerText = '00'
    } else {
        d = value.split(':')
        timerH.innerText = d[0]
        timerM.innerText = d[1]
        timerS.innerText = d[2]
    }
}

const getTimerData = async () => {
    let data = {
        uri: 'data',
        task: 'getTimer',
        presenterToken: presentertoken,
    }
    let apiRes = await api(data)
    let d = apiRes.data
    let startTime = new Date()
    let t = d.timer_value != null ? d.timer_value.split(':') : '00:00:00'.split(':')

    warnTime = d.timerWarn
    timerBlink = parseInt(d.timerBlink)
    timerCount = d.count

    setWarnTime()

    const StartTimeString = ((1000 * t[0] * 60 * 60) + (1000 * t[1] * 60) + (1000 * t[2]))
    if (d.timer_start == 1) {
        d.count == 'down' ? countDownTimer('countDownStart', startTime.setTime(parseInt(d.timer_start_time) + StartTimeString)) : countUpTimer('countUpStart', startTime.setTime(parseInt(d.timer_start_time) - StartTimeString))
    } else {
        timerH.innerText = t[0]
        timerM.innerText = t[1]
        timerS.innerText = t[2]
    }
}
getTimerData()


const setWarnTime = () => {
    let w = warnTime.split(':')
    warnTimeUnix = ((1000 * w[0] * 60 * 60) + (1000 * w[1] * 60) + (1000 * w[2]))
}

var distance

const countDownTimer = (cmd, startTime) => {
    if (cmd == 'countDownStart') {
        for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.add('blink')

        timerField.classList.remove('inactive')
        interval = setInterval(() => {
            distance = startTime - new Date().getTime();

            if (warnTimeUnix > distance && distance > 0) {
                timerField.classList.add('timeWarn')
            }

            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            timerH.innerText = (hours + '').padStart(2, "0")
            timerM.innerText = (minutes + '').padStart(2, "0")
            timerS.innerText = (seconds + '').padStart(2, "0")

            if (distance < 0) {
                clearInterval(interval);
                timerH.innerText = '00'
                timerM.innerText = '00'
                timerS.innerText = '00'
                countUpTimer('countUpStart', startTime - 100)
                timerField.classList.add('timeOver')
                if (timerBlink == true) {
                    timerField.classList.add('blinkScreen')
                } else {
                    timerField.classList.remove('blinkScreen')

                }
                for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.remove('blink')
            }
        }, 1000);

    } else {
        clearInterval(interval);
        if (distance < 0) {
            timerH.innerText = '00'
            timerM.innerText = '00'
            timerS.innerText = '00'
        }
        timerField.classList.remove('blinkScreen')
        timerField.classList.remove('timeOver')
        timerField.classList.remove('timeWarn')
        timerField.classList.add('inactive')
        for (var i = 0; i < timerSeperator.length; ++i) timerSeperator[i].classList.remove('blink')
    }
}

const countUpTimer = (cmd, startTime) => {
    if (cmd == 'countUpStart') {
        timerField.classList.remove('inactive')
        interval = setInterval(() => {
            var now = new Date().getTime();
            var distance = (now - startTime);
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            timerH.innerText = (hours + '').padStart(2, "0")
            timerM.innerText = (minutes + '').padStart(2, "0")
            timerS.innerText = (seconds + '').padStart(2, "0")

            if (timerBlink == true) {
                if (timerCount == 'down') {
                    timerField.classList.add('blinkScreen')
                }
            } else {
                timerField.classList.remove('blinkScreen')
            }

        }, 1000);

    } else {
        clearInterval(interval);
        timerField.classList.add('inactive')
    }
}

// Mobile-Devices
var ios = navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) ? true : false
var Firefox = navigator.userAgent.match(/Firefox/i) ? true : false
var Android = navigator.userAgent.match(/Android/i) ? true : false
var OtherDev = navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/webOS/i) ? true : false
var AndroidFF = Android && Firefox ? true : false
var wakeiOS = null
var wakeVideo = null

// All Mobile-Devices
if (ios || Android || OtherDev) {
    document.getElementById('keyboard').classList.add('hide')
}
//console.log('ios:', ios, 'Firefox:', Firefox, 'Android:', Android, 'andere:', OtherDev)

// iOS 
if (ios) {
    wakeiOS = new Audio('assets/sounds/silent.mp3')
    wakeiOS.title = "Presnetter"
    wakeiOS.author = "Neumann&Müller GmbH & Co. KG"
    wakeiOS.controls = false

    document.body.appendChild(wakeiOS);
    document.body.addEventListener('click', () => {
        wakeiOS.autoplay = true
    }, { once: true })

}

if ("mediaSession" in navigator) {
    navigator.mediaSession.metadata = new MediaMetadata({
        title: 'Presnetter - Internet Clicker',
        artist: 'Neumann&Müller GmbH & Co. KG',
        artwork: [
            { src: 'https://presnetter.de/assets/img/presnetter-96.png', sizes: '96x96', type: 'image/png' },
            { src: 'https://presnetter.de/assets/img/presnetter-192.png', sizes: '192x192', type: 'image/png' },
            { src: 'https://presnetter.de/assets/img/presnetter-256.png', sizes: '256x256', type: 'image/png' },
            { src: 'https://presnetter.de/assets/img/presnetter-512.png', sizes: '512x512', type: 'image/png' },
        ]
    });

    const actionHandlers = [
        ['previoustrack', () => { wsSend({ command: 'left' }) }],
        ['nexttrack', () => { wsSend({ command: 'right' }) }],
    ];

    for (const [action, handler] of actionHandlers) {
        try {
            navigator.mediaSession.setActionHandler(action, handler);
        } catch (error) { }
    }

}

// Android
if (Android || OtherDev) {

    const wakeOther = () => {
        wakeVideo = document.createElement('video')
        wakeVideo.src = 'assets/videos/output.mp4'
        if (wakeVideo.canPlayType("video/mp4")) {
            wakeVideo.src = 'assets/videos/output.mp4'
        } else {
            wakeVideo.src = 'assets/videos/output.webm'
        }
        wakeVideo.setAttribute('with', '200')
        wakeVideo.setAttribute('height', '200')
        wakeVideo.setAttribute('controls', 'controls')
        wakeVideo.setAttribute('playsinline', '')
        wakeVideo.classList.add('hide')
        wakeVideo.muted = false
        wakeVideo.loop = true
        wakeVideo.autoplay = true
        document.body.appendChild(wakeVideo);

        document.body.addEventListener('click', () => {
            wakeVideo.play()
        }, { once: true })
    }


    if (navigator.wakeLock != undefined) {

        const requestWakeLock = async () => {
            try {
                const wakeLock = await navigator.wakeLock.request('screen')
            } catch (err) {
                wakeOther()
            }
        }
        requestWakeLock();
    } else {
        wakeOther()
    }
}

const keepAwake = () => {
    if (ios) {
        if (wakeiOS.autoplay) {
            wakeiOS.play()
        }
    }
}




window.addEventListener("load", function () {
    // checkStarted()
});


/// NEW PEER
var pc
var broadcasterID
const peerConfig = {
    iceServers: [
        { urls: 'stun:turn-1.presnetter.de:3478' },
        {
            urls: ['turns:turn-1.presnetter.de:443', 'turn:turn-1.presnetter.de:3478'],
            username: 'presnetter',
            credential: '4a95eaabeb86afb371dfecbafe4e8a3328',
        }
    ]
}
const mediaConstraints = {
    mandatory: {
        OfferToReceiveAudio: true,
        OfferToReceiveVideo: true
    }
};

const sendPeerCMD = (command, data) => {
    socket.emit('peer', command, data)
}
socket.on('peer', (command, data) => {
    switch (command) {
        case 'requestCall':
            console.log('recieved CallRequest', data);
            broadcasterID = data.id
            callSender(broadcasterID)
            break;
        case 'offer':
            console.log('recieved OFFER', data);
            broadcasterID = data.id
            handleOffer(data.message);
            break;
    }
})



const callSender = (broadcasterID) => {
    let watcherID = presentertoken
    sendPeerCMD('watcher', { id: broadcasterID, message: watcherID })
}
const RTCPeerConnection = RTCPeerConnection || window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;


const handleOffer = async (offer) => {
    if (pc) {
        pc?.close()
    }
    try {
        await createPeerConnection();
        await pc.setRemoteDescription(offer);
        let answer = await pc.createAnswer();
        sendPeerCMD('answer', { id: broadcasterID, message: answer })
        await pc.setLocalDescription(answer);
        await pc.createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true });

    } catch (error) {
        console.error('PeerConnection failed: ', error);

    }
}

const createPeerConnection = async () => {
    try {
        pc = new RTCPeerConnection(peerConfig);
        pc.onicecandidate = (e) => {
            if (e.candidate) {
                console.log('ICE Candidate', e.candidate);
                sendPeerCMD('candidate', { id: broadcasterID, message: e.candidate })
            }

        };
        pc.onicecandidateerror = e => { console.error('%cICE Error', 'color: red', e.url, e.errorText, e.hostCandidate) };
        pc.onsignalingstatechange = e => { console.log('%cSignalingState', 'color: blue', e.srcElement.signalingState) };
        pc.ontrack = addStream
        console.log('PeerConnection created');

        pc.onconnectionstatechange = event => {
            let connectionState = event?.srcElement?.connectionState
            switch (connectionState) {
                case undefined:
                    console.log('peer not connected');
                    break;
                case 'disconnected':
                    console.log('peer disconnected', event.srcElement);
                    closeCall()
                    break;

                case 'failed':
                    console.error('%cpeer failed', 'color: red', event.srcElement);
                    closeCall()
                    break;
                case 'connecting':
                    console.log('%cpeer connecting ', 'color: orange', event.srcElement);
                    break;
                case 'connected':
                    console.log('%cpeer connected', 'color: green', event.srcElement);
                    break;


                default:
                    console.error(connectionState, event.srcElement);
                    break;
            }

        }
    } catch (error) {
        console.error('PeerConnection failed: ', error);

    }
}

// let sendOffer = () => {
//     console.log('Send offer');
//     pc.createOffer().then(
//         setAndSendLocalDescription,
//         (error) => {
//             console.error('Send offer failed: ', error);
//         }
//     );
// };

// let sendAnswer = () => {
//     console.log('Send answer');
//     pc.createAnswer().then(
//         setAndSendLocalDescription,
//         (error) => {
//             console.error('Send answer failed: ', error);
//         }
//     );
// };
// let setAndSendLocalDescription = (sessionDescription) => {
//     pc.setLocalDescription(sessionDescription);
//     console.log('Local description set');
//     sendPeerCMD('answer', { id: broadcasterID, message: sessionDescription })
// };

// const onIceCandidate = (e) => {
//     console.log('ICEcandidate: ', e);
//     if (e.candidate) {
//         console.log('ICE candidate');
//         sendPeerCMD('candidate', { id: broadcasterID, message: e.candidate })
//     }
// }

const addStream = (e) => {
    console.log('IncomingStream: ', e.streams[0], e);
    handleStream(e.streams[0])
}

socket.on("candidate", (id, candidate) => {
    console.log(candidate);
    pc
        .addIceCandidate(new RTCIceCandidate(candidate))
        .catch(e => console.error(e));
});

window.onunload = window.onbeforeunload = () => {
    pc?.close();
};

const closeCall = () => {
    pc?.close();
    destroyStream()
}


const remoteScreen = document.getElementById('remoteScreen')
var sourceStream = document.createElement('video')

/**
 * Creates remoteScreen DOM and inserts stream
 * @param {object} stream video srcObject
 */
const handleStream = (stream) => {
    try {
        document.body.classList.add('withVideo')

        remoteScreen.innerHTML = null
        sourceStream = document.createElement('video')
        sourceStream.srcObject = stream
        sourceStream.muted = true
        sourceStream.autoplay = true
        sourceStream.controls = true
        sourceStream.playsInline = true
        sourceStream.addEventListener('loadmetadata', () => {
            sourceStream.play()
        })
        remoteScreen.appendChild(sourceStream)
        sourceStream.controls = false
    } catch (error) {
        console.error(error);
    }


}

/**
 * Removes remoteScreen DOM
 */
const destroyStream = () => {
    document.body.classList.remove('withVideo')
    remoteScreen.innerHTML = null
}
