const { getCookie } = require('./cookie')
const { api } = require('./api')
const { i18n } = require('./i18next')

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)


const tokenInput = document.getElementById('presenterToken')
const termsAccept = document.getElementById('termsAccept')
const submitPresenter = document.getElementById('submitPresenter')
const msg = document.getElementById('msg')

var getByQR = false
var presenterToken = ''


var isAndroid = navigator.userAgent.match(/Android/i) ? true : false
const footer = document.getElementById('footer')
tokenInput.addEventListener('focusin', () => {
    if (isAndroid) footer.classList.add('hide')
})
tokenInput.addEventListener('focusout', () => {
    setTimeout(() => {
        footer.classList.remove('hide')
    }, 500)
})


if (urlParams.get('t') !== null) {
    getByQR = true
    presenterToken = urlParams.get('t')
    msg.classList.add('hide')
    tokenInput.classList.add('hide')

} else if (getCookie()?.data) {
    let req = {
        uri: 'login',
        task: 'loginPresenter',
        presenterToken: getCookie().data.token,
    }

    api(req)
        .then((data) => {
            if (data.error === undefined && getCookie().data !== undefined) {
                document.location.href = 'app'
            }
        })
}

if (presenterToken) tokenInput.value = presenterToken


submitPresenter.addEventListener('click', () => {
    presenterToken = tokenInput.value
    submitForm(presenterToken)
})
tokenInput.addEventListener('keyup', (e) => {
    if (e.key == 'Enter') {
        presenterToken = tokenInput.value
        submitForm(presenterToken)
    }
})

scanCode.addEventListener('click', () => document.location.href = 'scan')


async function submitForm(presenterToken) {
    console.log({ terms: termsAccept.checked, presenterToken })
    console.log('cond.', presenterToken != "", termsAccept.checked === true, presenterToken === "" || presenterToken == undefined)

    if (presenterToken != "" && termsAccept.checked === true) {

        let req = {
            uri: 'login',
            task: 'loginPresenter',
            presenterToken: presenterToken,
        }

        let apiRes = await api(req)
        let data = apiRes.data
        data.status = apiRes.status
        if (data.error === undefined && getCookie().data !== undefined) {
            document.location.href = 'app'
            return
        }
        msg.classList.remove('hide')
        tokenInput.classList.remove('hide')
        if (data.error === true && data.status == 500) {
            msg.innerHTML = data.message
            msg.classList.add('red')
            tokenInput.value = ''
        } else if (data.error === true && getByQR === true) {
            msg.innerHTML = i18n('tokenInvalid')
            msg.classList.add('red')
            tokenInput.value = ''
        } else if (data.error === true && getByQR === false) {
            msg.innerHTML = i18n('enterRightToken')
            msg.classList.add('red')
        }

    } else if (presenterToken === "" || presenterToken == undefined) {
        msg.innerHTML = i18n('enterYourToken')
        msg.classList.add('red')
    } else if (termsAccept.checked != true) {
        msg.innerHTML = i18n('pleaseAcceptFull')
        msg.classList.remove('hide')
        msg.classList.add('red')
        console.log('hello')
    }

}


window.addEventListener('resize', function () {
    let fixedWidth = 400;
    let fixedHeight = 900;

    window.resizeTo(fixedWidth, fixedHeight);
});